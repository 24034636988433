
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/auth";
import ReservationModule from "@/store/reservation";
import Cognito from "@/plugins/cognito";

@Component
export default class LoginForm extends Vue {
  private showPass = false;

  private showLabel = false;

  private loading = false;

  private get me() {
    return AuthModule.me;
  }

  private mounted() {
    if (this.me) {
      this.$router.push({ name: "MyPage" });
    }
  }

  private get isReservationFlow() {
    return ReservationModule.isReservationFlow;
  }

  private form = {
    email: "",
    password: "",
  };

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private passwordToggle() {
    this.showPass = !this.showPass;
  }
  private loginError() {
    this.showLabel = true;
    setTimeout(() => {
      this.showLabel = false;
    }, 3000);
  }
  private async submit() {
    try {
      this.loading = true;
      const user = await Cognito.signIn(this.form.email, this.form.password);
      if (user) {
        AuthModule.changeMe(user);
        // リダイレクト先のURLを取得します
        const redirect = this.$route.query.redirect;
        if (redirect) {
          // リダイレクト先のURLが存在する場合、そのページに遷移します
          this.$router.push({ name: redirect as string });
          return;
        }
        if (!this.isReservationFlow) {
          this.$router.push({ name: "MyPage" });
        } else {
          this.$router.push({ name: "CourseSelect" });
        }
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.loginError();
      throw new Error(error);
    }
  }
}
