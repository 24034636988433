
import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@/components/forms/LoginForm.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  name: "Login",
  components: {
    LoginForm,
    Header,
    Footer,
  },
})
export default class Login extends Vue {}
